import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "components/Layout";
import SEO from "components/SEO";
import { rhythm } from "utils/typography";

function PagePostTemplate({ data, location }) {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata.title;

  let breadcrumbConfig;
  if (post.frontmatter.showBreadcrumbs) {
    breadcrumbConfig = {
      url: post.fields.slug,
      content: (
        <Link to={post.fields.slug}>
          <h1 style={{ margin: `0rem` }}>{post.frontmatter.title}</h1>
        </Link>
      ),
      subTitle: post.frontmatter.description
    };
  }

  return post === null ? null : (
    <Layout
      location={location}
      title={siteTitle}
      showNewsletter={false}
      breadcrumbConfig={breadcrumbConfig}
    >
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(26)
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
    </Layout>
  );
}

PagePostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default PagePostTemplate;

export const pageQuery = graphql`
  query PagePostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        lang
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      timeToRead
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
  }
`;

